import { useLocation, useNavigate } from "@remix-run/react";
import { useEffect } from "react";
import { trackPageView } from "src/modules/analytics/api/analytics";
import { useUser } from "src/modules/auth/hooks/useUser";

import type { MetaFunction } from "@remix-run/node";

export const meta: MetaFunction = () => {
  return [
    { title: "TrueTracts" },
    { name: "description", content: "TrueTracts interface" },
  ];
};

export default function Index() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useUser();

  useEffect(() => {
    trackPageView(location.pathname);

    if (user) {
      navigate("/truetracts");
    } else {
      navigate("/auth/login");
    }
  }, []);

  return <div>Checking if user is authenticated...</div>;
}
