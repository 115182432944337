import { Container } from "@mui/material";
import * as React from "react";

import Header from "./Header";

interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps) {
  return (
    <Container disableGutters maxWidth={false}>
      <main
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        <Header />
        <Container
          disableGutters
          maxWidth={false}
          sx={{ flex: 1, overflow: "auto" }}
        >
          {children}
        </Container>
      </main>
    </Container>
  );
}
