import ReactGA from "react-ga4";

export const initializeAnalytics = () => {
  const trackingId = import.meta.env.PUBLIC_GA_TRACKING_ID as
    | string
    | undefined;

  if (typeof trackingId !== "string" || !trackingId) {
    console.warn("Google Analytics tracking ID not found or invalid");
    return;
  }

  ReactGA.initialize(trackingId);
};

export const trackPageView = (path: string, title?: string) => {
  ReactGA.send({
    hitType: "pageview",
    page: path,
    title,
  } as const);
};

export const setUserProperties = (email?: string, name?: string) => {
  if (!email && !name) return;

  ReactGA.set({
    user_properties: {
      email,
      name,
    } as const,
  });
};

interface TrackEventParams {
  category: string;
  action: string;
  label?: string;
  value?: number;
  nonInteraction?: boolean;
  transport?: "beacon" | "xhr" | "image";
}

export const trackEvent = ({
  category,
  action,
  label,
  value,
  nonInteraction,
  transport,
}: TrackEventParams) => {
  ReactGA.event({
    category,
    action,
    label,
    value,
    nonInteraction,
    transport,
  } as const);
};
