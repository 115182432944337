import { colors, createTheme } from "@mui/material";

// Create a theme instance.
const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#38734c",
    },
    secondary: {
      main: "#9b3f71",
    },
    error: {
      main: colors.red.A400,
    },
  },
  typography: {
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  components: {
    MuiTooltip: {
      defaultProps: {
        enterDelay: 0,
      },
    },
  },
});

export default theme;
