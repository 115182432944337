import React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
interface LoadingProps {
  children: React.ReactNode;
  suspenseFallback?: React.ReactNode;
  errorFallback?: (props: FallbackProps) => React.ReactNode;
}

export default function Loading({
  children,
  suspenseFallback,
  errorFallback,
}: LoadingProps) {
  return (
    <ErrorBoundary fallbackRender={errorFallback ?? (() => null)}>
      <React.Suspense fallback={suspenseFallback ?? <>Loading...</>}>
        {children}
      </React.Suspense>
    </ErrorBoundary>
  );
}
