import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Avatar, IconButton } from "@mui/material";
import { useSetAtom } from "jotai";

import { supportModalVisibilityAtom } from "../../state/supportModalState";
import SupportModal from "./SupportModal";

export default function SupportButton() {
  const setModalOpen = useSetAtom(supportModalVisibilityAtom);

  return (
    <>
      <IconButton onClick={() => setModalOpen(true)}>
        <Avatar sx={{ bgcolor: "secondary.main", width: 32, height: 32 }}>
          <HelpOutlineIcon sx={{ fontSize: 25, fontWeight: "bold" }} />
        </Avatar>
      </IconButton>
      <SupportModal />
    </>
  );
}
