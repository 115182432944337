import { Button } from "@mui/material";
import { Form } from "@remix-run/react";

export default function SignUpButton() {
  return (
    <Form action="/auth/login?screen_hint=signup" method="post">
      <Button type="submit" variant="text">
        No Account? Sign Up
      </Button>
    </Form>
  );
}
