import { Button } from "@mui/material";
import { Form } from "@remix-run/react";

import SignUpButton from "./SignUpButton";

export default function LoginButton() {
  return (
    <>
      <Form action="/auth/login" method="post">
        <Button type="submit" variant="outlined">
          Login
        </Button>
      </Form>
      <SignUpButton />
    </>
  );
}
