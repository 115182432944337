import { Chip } from "@mui/material";

import Loading from "../Loading";

interface ChipLoadingProps {
  children: React.ReactNode;
}

export default function ChipLoading({ children }: ChipLoadingProps) {
  return (
    <Loading suspenseFallback={<Chip label={"Loading..."} />}>
      {children}
    </Loading>
  );
}
