/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import * as Sentry from "@sentry/remix";
import { startTransition, useEffect /*, StrictMode*/ } from "react";
import { hydrateRoot } from "react-dom/client";

import { MuiProvider } from "./mui/MuiProvider";

if (!import.meta.env.PROD) {
  console.warn("Not loading Sentry due to non-production environment");
}

if (
  import.meta.env.PROD &&
  (import.meta.env.PUBLIC_SENTRY_DSN as string) != null
) {
  Sentry.init({
    dsn: import.meta.env.PUBLIC_SENTRY_DSN as string,
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
      // Replay is only available in the client
      Sentry.replayIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
    release:
      "true-tracts-alpha@" + (import.meta.env.PUBLIC_VERSION ?? "unknown"),

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/neighbourhoods-be\.onrender\.com/,
      /^https:\/\/truetracts\.truefootage\.tech/,
      /^https:\/\/api\.truetracts\.truefootage\.tech/,
      /^https:\/\/truetracts-qa\.truefootage\.tech/,
      /^https:\/\/api\.truetracts-qa\.truefootage\.tech/,
      /^https:\/\/trueengine\.truefootage\.tech/,
      /^https:\/\/api\.trueengine\.truefootage\.tech/,
      /^https:\/\/trueengine-qa\.truefootage\.tech/,
      /^https:\/\/api\.trueengine-qa\.truefootage\.tech/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 0.5,
  });
}

startTransition(() => {
  hydrateRoot(
    document,
    //<StrictMode>
    <MuiProvider>
      <RemixBrowser />
    </MuiProvider>,
    //</StrictMode>,
  );
});
