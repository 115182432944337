import { useLocation } from "@remix-run/react";
import { useEffect } from "react";
import { useUser } from "src/modules/auth/hooks/useUser";

import {
  initializeAnalytics,
  setUserProperties,
  trackPageView,
} from "../api/analytics";

interface AnalyticsProviderProps {
  children: React.ReactNode;
}

export function AnalyticsProvider({ children }: AnalyticsProviderProps) {
  const location = useLocation();
  const { user } = useUser();

  useEffect(() => {
    initializeAnalytics();
  }, []);

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  useEffect(() => {
    if (user?.profile?._json) {
      setUserProperties(user.profile._json.email, user.profile._json.name);
    }
  }, [user]);

  return children;
}
