import { atom } from "jotai";

export enum ValuationProcessStep {
  Boundaries,
  CompRecommendation,
  CompRec,
  MarketAnalysis,
  Adjustments,
  Reconciliation,
  Export,
  Sessions,
}

const _currentStepAtom = atom<ValuationProcessStep>(
  ValuationProcessStep.Sessions,
);

export const currentStepAtom = atom<ValuationProcessStep>((get) =>
  get(_currentStepAtom),
);

export const selectStepAtom = atom(
  null,
  (get, set, step: ValuationProcessStep) => {
    set(_currentStepAtom, step);
  },
);
